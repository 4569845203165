/*
File generated by js-routes 1.4.14
Based on Rails 6.0.6.1 routes of Shopflow::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// account => /app/accounts/:id(.:format)
  // function(id, options)
  account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// accounts => /app/accounts(.:format)
  // function(options)
  accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// active_users => /app/users/active(.:format)
  // function(options)
  active_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"active",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// addCardWorkOrder => /app/addCardWorkOrder(.:format)
  // function(options)
  addCardWorkOrder_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"addCardWorkOrder",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_jobs => /api/export/jobs(.:format)
  // function(options)
  api_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"export",false],[2,[7,"/",false],[2,[6,"jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_jobs_nested => /api/export/jobs-nested(.:format)
  // function(options)
  api_jobs_nested_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"export",false],[2,[7,"/",false],[2,[6,"jobs-nested",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_operations => /api/export/operations(.:format)
  // function(options)
  api_operations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"export",false],[2,[7,"/",false],[2,[6,"operations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_stages => /api/export/stages(.:format)
  // function(options)
  api_stages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"export",false],[2,[7,"/",false],[2,[6,"stages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_team_members => /api/export/team-members(.:format)
  // function(options)
  api_team_members_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"export",false],[2,[7,"/",false],[2,[6,"team-members",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_teams => /api/export/teams(.:format)
  // function(options)
  api_teams_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"export",false],[2,[7,"/",false],[2,[6,"teams",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_work_orders => /api/export/work-orders(.:format)
  // function(options)
  api_work_orders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"export",false],[2,[7,"/",false],[2,[6,"work-orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// applyCardWorkflow => /app/applyCardWorkflow(.:format)
  // function(options)
  applyCardWorkflow_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"applyCardWorkflow",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// archive_board => /app/boards/:id/archive(.:format)
  // function(id, options)
  archive_board_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"boards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// archive_job => /app/jobs/:id/archive(.:format)
  // function(id, options)
  archive_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// archive_location => /app/locations/:id/archive(.:format)
  // function(id, options)
  archive_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// archive_stage => /app/stages/:id/archive(.:format)
  // function(id, options)
  archive_stage_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"stages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// archive_tag => /app/tags/:id/archive(.:format)
  // function(id, options)
  archive_tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// archive_team => /app/teams/:id/archive(.:format)
  // function(id, options)
  archive_team_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// archive_template => /app/templates/:id/archive(.:format)
  // function(id, options)
  archive_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// assignCardTemplate => /app/assignCardTemplate(.:format)
  // function(options)
  assignCardTemplate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"assignCardTemplate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// attachment => /app/attachments/:id(.:format)
  // function(id, options)
  attachment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// attachments => /app/attachments(.:format)
  // function(options)
  attachments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// base => /*path(.:format)
  // function(path, options)
  base_path: Utils.route([["path",true],["format",false]], {}, [2,[7,"/",false],[2,[5,[3,"path",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// board => /app/boards/:id(.:format)
  // function(id, options)
  board_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"boards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// boards => /app/boards(.:format)
  // function(options)
  boards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"boards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// calendar => /app/calendar(.:format)
  // function(options)
  calendar_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"calendar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cancel_user_registration => /app/user_account/cancel(.:format)
  // function(options)
  cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"user_account",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// change_password => /app/users/change_password(.:format)
  // function(options)
  change_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"change_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// check_password => /app/users/check_password(.:format)
  // function(options)
  check_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"check_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// clock_out => /app/time_clock/clock_out(.:format)
  // function(options)
  clock_out_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"time_clock",false],[2,[7,"/",false],[2,[6,"clock_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// colors => /app/colors(.:format)
  // function(options)
  colors_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"colors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// comments_job => /app/jobs/:id/comments(.:format)
  // function(id, options)
  comments_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// config => /app/config(.:format)
  // function(options)
  config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contact => /app/contacts/:id(.:format)
  // function(id, options)
  contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contacts => /app/contacts(.:format)
  // function(options)
  contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// current_account => /app/account(.:format)
  // function(options)
  current_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// current_time_card => /app/time_cards/current(.:format)
  // function(options)
  current_time_card_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"time_cards",false],[2,[7,"/",false],[2,[6,"current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// current_time_clock => /app/time_clock/current(.:format)
  // function(options)
  current_time_clock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"time_clock",false],[2,[7,"/",false],[2,[6,"current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// demos_submit_signup => /app/demos/submit_signup(.:format)
  // function(options)
  demos_submit_signup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"demos",false],[2,[7,"/",false],[2,[6,"submit_signup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_user_session => /app/user_account/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"user_account",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_account => /app/accounts/:id/edit(.:format)
  // function(id, options)
  edit_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_attachment => /app/attachments/:id/edit(.:format)
  // function(id, options)
  edit_attachment_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_board => /app/boards/:id/edit(.:format)
  // function(id, options)
  edit_board_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"boards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_contact => /app/contacts/:id/edit(.:format)
  // function(id, options)
  edit_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_email => /app/emails/:id/edit(.:format)
  // function(id, options)
  edit_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_item => /app/items/:id/edit(.:format)
  // function(id, options)
  edit_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_job => /app/jobs/:id/edit(.:format)
  // function(id, options)
  edit_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_labor_operation => /app/labor_operations/:id/edit(.:format)
  // function(id, options)
  edit_labor_operation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"labor_operations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_location => /app/locations/:id/edit(.:format)
  // function(id, options)
  edit_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_member => /app/members/:id/edit(.:format)
  // function(id, options)
  edit_member_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"members",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_note => /app/notes/:id/edit(.:format)
  // function(id, options)
  edit_note_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_stage => /app/stages/:id/edit(.:format)
  // function(id, options)
  edit_stage_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"stages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_tag => /app/tags/:id/edit(.:format)
  // function(id, options)
  edit_tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_team => /app/teams/:id/edit(.:format)
  // function(id, options)
  edit_team_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_template => /app/templates/:id/edit(.:format)
  // function(id, options)
  edit_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_time_card => /app/time_cards/:id/edit(.:format)
  // function(id, options)
  edit_time_card_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"time_cards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_time_entry => /app/time_entries/:id/edit(.:format)
  // function(id, options)
  edit_time_entry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"time_entries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user => /app/users/:id/edit(.:format)
  // function(id, options)
  edit_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_password => /app/user_account/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"user_account",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_registration => /app/user_account/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"user_account",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_work_order_template => /app/work_order_templates/:id/edit(.:format)
  // function(id, options)
  edit_work_order_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"work_order_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// email => /app/emails/:id(.:format)
  // function(id, options)
  email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// emails => /app/emails(.:format)
  // function(options)
  emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// emails_inbound => /emails/inbound(.:format)
  // function(options)
  emails_inbound_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[6,"inbound",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// emails_job => /app/jobs/:id/emails(.:format)
  // function(id, options)
  emails_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// fullnames_jobs => /app/jobs/fullnames(.:format)
  // function(options)
  fullnames_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"fullnames",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// home => /app/home(.:format)
  // function(options)
  home_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"home",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// item => /app/items/:id(.:format)
  // function(id, options)
  item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// items => /app/items(.:format)
  // function(options)
  items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// job => /app/jobs/:id(.:format)
  // function(id, options)
  job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_query => /app/jobs/query(.:format)
  // function(options)
  job_query_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"query",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// jobs => /app/jobs(.:format)
  // function(options)
  jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// labor_operation => /app/labor_operations/:id(.:format)
  // function(id, options)
  labor_operation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"labor_operations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// labor_operations => /app/labor_operations(.:format)
  // function(options)
  labor_operations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"labor_operations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// labor_report => /app/laborReport(.:format)
  // function(options)
  labor_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"laborReport",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lead_times_report => /app/leadTimesReport(.:format)
  // function(options)
  lead_times_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"leadTimesReport",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// location => /app/locations/:id(.:format)
  // function(id, options)
  location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// locations => /app/locations(.:format)
  // function(options)
  locations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// member => /app/members/:id(.:format)
  // function(id, options)
  member_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"members",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// members => /app/members(.:format)
  // function(options)
  members_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"members",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// move_location => /app/locations/:id/move(.:format)
  // function(id, options)
  move_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// my_work => /app/myWork(.:format)
  // function(options)
  my_work_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"myWork",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_account => /app/accounts/new(.:format)
  // function(options)
  new_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_attachment => /app/attachments/new(.:format)
  // function(options)
  new_attachment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_board => /app/boards/new(.:format)
  // function(options)
  new_board_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"boards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_contact => /app/contacts/new(.:format)
  // function(options)
  new_contact_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_email => /app/emails/new(.:format)
  // function(options)
  new_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_item => /app/items/new(.:format)
  // function(options)
  new_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_job => /app/jobs/new(.:format)
  // function(options)
  new_job_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_labor_operation => /app/labor_operations/new(.:format)
  // function(options)
  new_labor_operation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"labor_operations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_location => /app/locations/new(.:format)
  // function(options)
  new_location_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_member => /app/members/new(.:format)
  // function(options)
  new_member_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"members",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_note => /app/notes/new(.:format)
  // function(options)
  new_note_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_stage => /app/stages/new(.:format)
  // function(options)
  new_stage_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"stages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_tag => /app/tags/new(.:format)
  // function(options)
  new_tag_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_team => /app/teams/new(.:format)
  // function(options)
  new_team_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_template => /app/templates/new(.:format)
  // function(options)
  new_template_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_time_card => /app/time_cards/new(.:format)
  // function(options)
  new_time_card_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"time_cards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_time_entry => /app/time_entries/new(.:format)
  // function(options)
  new_time_entry_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"time_entries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user => /app/users/new(.:format)
  // function(options)
  new_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_password => /app/user_account/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"user_account",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_user_registration => /app/user_account/sign_up(.:format)
  // function(options)
  new_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"user_account",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_session => /app/user_account/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"user_account",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_work_order_template => /app/work_order_templates/new(.:format)
  // function(options)
  new_work_order_template_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"work_order_templates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// note => /app/notes/:id(.:format)
  // function(id, options)
  note_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// notes => /app/notes(.:format)
  // function(options)
  notes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// path_job => /app/jobs/:id/path(.:format)
  // function(id, options)
  path_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"path",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// payroll => /app/payroll(.:format)
  // function(options)
  payroll_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"payroll",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payroll_export_qb => /app/payroll/exportQB(.:format)
  // function(options)
  payroll_export_qb_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[6,"exportQB",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// pg_hero => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58
  // function(options)
  pg_hero_path: Utils.route([], {}, [2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]]),
// pg_hero.space => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/space(.:format)
  // function(options)
  pg_hero_space_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"space",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.relation_space => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/space/:relation(.:format)
  // function(relation, options)
  pg_hero_relation_space_path: Utils.route([["database",false],["relation",true],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"space",false],[2,[7,"/",false],[2,[3,"relation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// pg_hero.index_bloat => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/index_bloat(.:format)
  // function(options)
  pg_hero_index_bloat_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"index_bloat",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.live_queries => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/live_queries(.:format)
  // function(options)
  pg_hero_live_queries_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"live_queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.queries => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/queries(.:format)
  // function(options)
  pg_hero_queries_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.show_query => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/queries/:query_hash(.:format)
  // function(query_hash, options)
  pg_hero_show_query_path: Utils.route([["database",false],["query_hash",true],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"queries",false],[2,[7,"/",false],[2,[3,"query_hash",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// pg_hero.system => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/system(.:format)
  // function(options)
  pg_hero_system_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"system",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.cpu_usage => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/cpu_usage(.:format)
  // function(options)
  pg_hero_cpu_usage_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"cpu_usage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.connection_stats => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/connection_stats(.:format)
  // function(options)
  pg_hero_connection_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"connection_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.replication_lag_stats => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/replication_lag_stats(.:format)
  // function(options)
  pg_hero_replication_lag_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"replication_lag_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.load_stats => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/load_stats(.:format)
  // function(options)
  pg_hero_load_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"load_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.free_space_stats => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/free_space_stats(.:format)
  // function(options)
  pg_hero_free_space_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"free_space_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.explain => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/explain(.:format)
  // function(options)
  pg_hero_explain_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"explain",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.tune => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/tune(.:format)
  // function(options)
  pg_hero_tune_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"tune",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.connections => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/connections(.:format)
  // function(options)
  pg_hero_connections_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"connections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.maintenance => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/maintenance(.:format)
  // function(options)
  pg_hero_maintenance_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"maintenance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.kill => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/kill(.:format)
  // function(options)
  pg_hero_kill_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"kill",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.kill_long_running_queries => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/kill_long_running_queries(.:format)
  // function(options)
  pg_hero_kill_long_running_queries_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"kill_long_running_queries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.kill_all => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/kill_all(.:format)
  // function(options)
  pg_hero_kill_all_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"kill_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.enable_query_stats => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/enable_query_stats(.:format)
  // function(options)
  pg_hero_enable_query_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"enable_query_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.reset_query_stats => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/reset_query_stats(.:format)
  // function(options)
  pg_hero_reset_query_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"reset_query_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.system_stats => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/system_stats(.:format)
  // function(options)
  pg_hero_system_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"system_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.query_stats => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58(/:database)/query_stats(.:format)
  // function(options)
  pg_hero_query_stats_path: Utils.route([["database",false],["format",false]], {}, [2,[1,[2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[3,"database",false]],false],[2,[7,"/",false],[2,[6,"query_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// pg_hero.root => /db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58/(:database)(.:format)
  // function(options)
  pg_hero_root_path: Utils.route([["database",false],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"db-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]],[7,"/",false]],[2,[1,[3,"database",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// planning => /app/planning(.:format)
  // function(options)
  planning_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"planning",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// purchasing_report => /app/purchasingReport(.:format)
  // function(options)
  purchasing_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"purchasingReport",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// qbo_auth => /app/qbo/auth(.:format)
  // function(options)
  qbo_auth_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"qbo",false],[2,[7,"/",false],[2,[6,"auth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// qbo_disconnect => /app/qbo/disconnect(.:format)
  // function(options)
  qbo_disconnect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"qbo",false],[2,[7,"/",false],[2,[6,"disconnect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// qbo_init => /qbo/init(.:format)
  // function(options)
  qbo_init_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"qbo",false],[2,[7,"/",false],[2,[6,"init",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// qbwc_action => /qbwc/action(.:format)
  // function(options)
  qbwc_action_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"qbwc",false],[2,[7,"/",false],[2,[6,"action",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// qbwc_soap => /qbwc/action
  // function(options)
  qbwc_soap_path: Utils.route([], {}, [2,[7,"/",false],[2,[6,"qbwc",false],[2,[7,"/",false],[6,"action",false]]]]),
// qbwc_wsdl => /qbwc/wsdl
  // function(options)
  qbwc_wsdl_path: Utils.route([], {}, [2,[7,"/",false],[2,[6,"qbwc",false],[2,[7,"/",false],[6,"wsdl",false]]]]),
// qwc => /quickbooks/qwc(.:format)
  // function(options)
  qwc_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"quickbooks",false],[2,[7,"/",false],[2,[6,"qwc",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reset_password_user => /app/users/:id/reset_password(.:format)
  // function(id, options)
  reset_password_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_board => /app/boards/:id/restore(.:format)
  // function(id, options)
  restore_board_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"boards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_job => /app/jobs/:id/restore(.:format)
  // function(id, options)
  restore_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_location => /app/locations/:id/restore(.:format)
  // function(id, options)
  restore_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_stage => /app/stages/:id/restore(.:format)
  // function(id, options)
  restore_stage_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"stages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_tag => /app/tags/:id/restore(.:format)
  // function(id, options)
  restore_tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_team => /app/teams/:id/restore(.:format)
  // function(id, options)
  restore_team_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restore_template => /app/templates/:id/restore(.:format)
  // function(id, options)
  restore_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// schedule_cards => /app/schedule/cards(.:format)
  // function(options)
  schedule_cards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"schedule",false],[2,[7,"/",false],[2,[6,"cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// schedule_groups => /app/schedule/groups(.:format)
  // function(options)
  schedule_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"schedule",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// schedule_reset => /app/scheduleReset(.:format)
  // function(options)
  schedule_reset_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"scheduleReset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// schedule_update => /app/schedule/update(.:format)
  // function(options)
  schedule_update_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"schedule",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// send_email_reset_password => /app/users/send_email_reset_password(.:format)
  // function(options)
  send_email_reset_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"send_email_reset_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sidekiq_web => /bg-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58
  // function(options)
  sidekiq_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"bg-stats-c7bec8a3-b69c-460a-bbb4-86711b84ec58",false]]),
// stage => /app/stages/:id(.:format)
  // function(id, options)
  stage_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"stages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// stages => /app/stages(.:format)
  // function(options)
  stages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"stages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// stages_report => /app/stagesReport(.:format)
  // function(options)
  stages_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"stagesReport",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_account => /app/accounts(.:format)
  // function(options)
  submit_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_attachment => /app/attachments(.:format)
  // function(options)
  submit_attachment_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_board => /app/boards(.:format)
  // function(options)
  submit_board_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"boards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_comment => /app/:commentable_type/:commentable_id/comments(.:format)
  // function(commentable_type, commentable_id, options)
  submit_comment_path: Utils.route([["commentable_type",true],["commentable_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[3,"commentable_type",false],[2,[7,"/",false],[2,[3,"commentable_id",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// submit_contact => /app/contacts(.:format)
  // function(options)
  submit_contact_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_email => /app/emails(.:format)
  // function(options)
  submit_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_item => /app/items(.:format)
  // function(options)
  submit_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_job => /app/jobs(.:format)
  // function(options)
  submit_job_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_labor_operation => /app/labor_operations(.:format)
  // function(options)
  submit_labor_operation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"labor_operations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_location => /app/locations(.:format)
  // function(options)
  submit_location_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_member => /app/members(.:format)
  // function(options)
  submit_member_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"members",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_note => /app/notes(.:format)
  // function(options)
  submit_note_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_stage => /app/stages(.:format)
  // function(options)
  submit_stage_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"stages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_submission => /app/submission(.:format)
  // function(options)
  submit_submission_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"submission",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_tag => /app/tags(.:format)
  // function(options)
  submit_tag_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_team => /app/teams(.:format)
  // function(options)
  submit_team_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"teams",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_template => /app/templates(.:format)
  // function(options)
  submit_template_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_time_entry => /app/time_entries(.:format)
  // function(options)
  submit_time_entry_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"time_entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// submit_user => /app/users(.:format)
  // function(options)
  submit_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// subtree_jobs => /app/jobs/subtree(.:format)
  // function(options)
  subtree_jobs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"subtree",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// switch_account => /app/accounts/:id/switch(.:format)
  // function(id, options)
  switch_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"switch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// tag => /app/tags/:id(.:format)
  // function(id, options)
  tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tags => /app/tags(.:format)
  // function(options)
  tags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// team => /app/teams/:id(.:format)
  // function(id, options)
  team_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"teams",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// teams => /app/teams(.:format)
  // function(options)
  teams_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"teams",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// template => /app/templates/:id(.:format)
  // function(id, options)
  template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// templates => /app/templates(.:format)
  // function(options)
  templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// time_card => /app/time_cards/:id(.:format)
  // function(id, options)
  time_card_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"time_cards",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// time_cards => /app/time_cards(.:format)
  // function(options)
  time_cards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"time_cards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// time_entries => /app/time_entries(.:format)
  // function(options)
  time_entries_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"time_entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// time_entries_job => /app/jobs/:id/time_entries(.:format)
  // function(id, options)
  time_entries_job_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"time_entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// time_entry => /app/time_entries/:id(.:format)
  // function(id, options)
  time_entry_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"time_entries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// time_logs => /app/time_logs(.:format)
  // function(options)
  time_logs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"time_logs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_photo => /app/users/update_photo(.:format)
  // function(options)
  update_photo_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"update_photo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// upload_photo => /app/users/upload_photo(.:format)
  // function(options)
  upload_photo_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"upload_photo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// uploads => /app/uploads(.:format)
  // function(options)
  uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user => /app/users/:id(.:format)
  // function(id, options)
  user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_password => /app/user_account/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"user_account",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_registration => /app/user_account(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"user_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_session => /app/user_account/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"user_account",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// users => /app/users(.:format)
  // function(options)
  users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// whats_next => /app/whatsNext(.:format)
  // function(options)
  whats_next_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"whatsNext",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// work_items => /app/workItems(.:format)
  // function(options)
  work_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"workItems",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// work_order_template => /app/work_order_templates/:id(.:format)
  // function(id, options)
  work_order_template_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"work_order_templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// work_order_templates => /app/work_order_templates(.:format)
  // function(options)
  work_order_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"work_order_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// workload => /app/workload(.:format)
  // function(options)
  workload_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"workload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// wysiwyg_image => /app/wysiwyg_image(.:format)
  // function(options)
  wysiwyg_image_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"wysiwyg_image",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);

